<template>
  <select
    v-model="booking.playgrounds[0]"
    disabled
    class="form-control background-light-blue-inputs"
    size="sm"
    @change="onChange"
  >
    <template v-for="(playground, key) of playgrounds">
      <option
        :key="'category' + key"
        :value="playground['@id']"
      >
        {{ playground.name }}
      </option>
    </template>
  </select>
</template>
<script>

import {LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";

export default {
  props: {
    booking: {
      type: Object,
      default: this
    },
    selectedPlayground: {
      type: Object,
      default: this
    },
    bookingType: {
      type: String,
      default: 'default'
    },
    validation: {
      type: Object,
      default: this
    },
  },
  computed: {
    isDefaultBooking () {
      return this.bookingType === LESSON_BOOKING_TYPE;
    },
    playgrounds() {
      return this.$store.getters["playgrounds/getPlaygrounds"];
    }
  },
  methods: {
    onChange() {
      this.$bus.$emit('reload-activities');
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
